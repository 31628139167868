<template>
  <div class="page-container categories-page">
    <portal to="page-name">Gerenciamento de categorias</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Categorias</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <template v-if="categories.length === 0">
            <div class="empty">
              <div class="empty-icon mt-2">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Categorias</p>
              <p class="empty-subtitle">
                Você não possui nenhuma categoria cadastrada. O que deseja fazer?
              </p>
              <div class="action">
                <button class="btn btn-primary btn-icon btn-icon-left mr-2"
                        @click="open({ id: '', type: 'revenue' })">
                  <fa-icon :icon="['fal', 'plus']"/>Nova categoria de receita
                </button>
                <button class="btn btn-warning btn-icon btn-icon-left mr-2"
                        @click="open({ id: '', type: 'expense' })">
                  <fa-icon :icon="['fal', 'plus']"/>Nova categoria de despesa
                </button>
                <button class="btn btn-info btn-icon btn-icon-left"
                        @click="openModel">
                  <fa-icon :icon="['fal', 'info-circle']"/>Utilizar modelo do sistema
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- Receipts -->
            <div class="category">
              <div class="columns mb-2">
                <div class="column">
                  <div class="h5">Categorias de Receita</div>
                </div>
                <div class="column centered text-right">
                  <button class="btn btn-primary btn-icon btn-icon-left"
                          @click="open({ id: '', type: 'revenue' })">
                    <fa-icon :icon="['fal', 'plus']"/>Nova categoria de receita
                  </button>
                </div>
              </div>
              <div class="empty" v-if="filteredReceiptCategories.length === 0">
                <div class="empty-icon">
                  <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
                </div>
                <p class="empty-subtitle">
                  Nenhuma categoria de receita cadastrada
                </p>
              </div>
              <div v-for="first in filteredReceiptCategories" :key="first.id" v-else>
                <item
                  :data="first"
                  :new-category="true"
                  @open="open"
                  @edit="edit"
                  @remove="remove"
                />
                <div v-if="first.items && first.items.length > 0">
                  <div v-for="secondary in first.items" :key="secondary.id">
                    <item
                      class-style="sub-item secondary"
                      :data="secondary"
                      :new-category="true"
                      @open="open"
                      @edit="edit"
                      @remove="remove"
                    />
                    <div v-if="secondary.items && secondary.items.length > 0">
                      <div v-for="third in secondary.items" :key="third.id">
                        <item
                          class-style="sub-item third"
                          :data="third"
                          @open="open"
                          @edit="edit"
                          @remove="remove"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Expenses -->
            <div class="category">
              <div class="columns mb-2">
                <div class="column">
                  <div class="h5">Categorias de Despesa</div>
                </div>
                <div class="column centered text-right">
                  <button class="btn btn-primary btn-icon btn-icon-left"
                          @click="open({ id: '', type: 'expense' })">
                    <fa-icon :icon="['fal', 'plus']"/>Nova categoria de despesa
                  </button>
                </div>
              </div>
              <div class="empty" v-if="filteredExpenseCategories.length === 0">
                <div class="empty-icon">
                  <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
                </div>
                <p class="empty-subtitle">
                  Nenhuma categoria de despesa cadastrada
                </p>
              </div>
              <div v-for="first in filteredExpenseCategories" :key="first.id" v-else>
                <item
                  :data="first"
                  :new-category="true"
                  @open="open"
                  @edit="edit"
                  @remove="remove"
                />
                <div v-if="first.items && first.items.length > 0">
                  <div v-for="secondary in first.items" :key="secondary.id">
                    <item
                      class-style="sub-item secondary"
                      :data="secondary"
                      :new-category="true"
                      @open="open"
                      @edit="edit"
                      @remove="remove"
                    />
                    <div v-if="secondary.items && secondary.items.length > 0">
                      <div v-for="third in secondary.items" :key="third.id">
                        <item
                          class-style="sub-item third"
                          :data="third"
                          @open="open"
                          @edit="edit"
                          @remove="remove"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <modal-form
      v-if="modal.show"
      :id="modal.id"
      :show="modal.show"
      :type="modal.type"
      :dre="modal.dre"
      :parent-id="modal.parentId"
      :categories="categories"
      @close="close"
    />
    <dx-modal title="Modelo de categorias do sistema"
              v-model="modalCategoryModel.show" size="md"
              v-if="modalCategoryModel.show"
              id="modal-model">
      <div class="loading loading-lg mt-2" v-if="modalCategoryModel.loading" />
      <table class="table" v-else>
        <tbody>
        <tr v-for="model in modalCategoryModel.models" :key="model.id">
          <td>
            <strong>{{ model.name }}</strong>
            <div class="ml-2" v-for="first in model.items" :key="first.id">
              <span>- {{ first.name }}</span>
              <div class="ml-2" v-for="secondary in first.items" :key="secondary.id">
                <span class="ml-2">{{ secondary.name }}</span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: modalCategoryModel.saving}"
                :disabled="modalCategoryModel.saving"
                @click="addCategoryModel">
          <fa-icon :icon="['fal', 'plus']" />Adicionar
        </button>
        <button class="btn" @click="modalCategoryModel.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import item from './Item.vue';
import modalForm from './Modal.vue';

export default {
  components: {
    item,
    modalForm,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      categories: [],
      modal: {
        id: null,
        type: null,
        dre: null,
        parentId: '',
        show: false,
      },
      modalCategoryModel: {
        show: false,
        loading: false,
        saving: false,
        models: [],
      },
    };
  },
  computed: {
    filteredReceiptCategories() {
      return this.categories.filter(({ type }) => type === 'revenue');
    },
    filteredExpenseCategories() {
      return this.categories.filter(({ type }) => type === 'expense');
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      this.categories = [];
      this.$http.get('/categories')
        .then(({ data }) => {
          this.categories = this.formatCategories(data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    open(args) {
      this.modal.id = null;
      this.modal.parentId = args.id || '';
      this.modal.type = args.type;
      this.modal.dre = args.dre;
      this.modal.show = true;
    },
    edit(args) {
      this.modal.id = args.id;
      this.modal.parent = '';
      this.modal.type = null;
      this.modal.show = true;
    },
    close() {
      this.load();
      this.modal.show = false;
    },
    loadCategoryModel() {
      this.modalCategoryModel.loading = true;

      this.$http.get('/categories/model')
        .then(({ data }) => {
          this.modalCategoryModel.models = this.formatCategories(data);
        })
        .catch(() => {})
        .finally(() => {
          this.modalCategoryModel.loading = false;
        });
    },
    addCategoryModel() {
      this.modalCategoryModel.saving = true;

      return this.$http.post('/categories/model')
        .then(() => {
          this.load();
          this.modalCategoryModel.show = false;
        })
        .catch(() => {})
        .finally(() => {
          this.modalCategoryModel.saving = false;
        });
    },
    openModel() {
      this.loadCategoryModel();
      this.modalCategoryModel.show = true;
    },
    remove(args) {
      if (this.deleting) {
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente remover esta categoria?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              return this.$http.delete(`/categories/${args.id}`)
                .catch(() => {})
                .finally(() => {
                  this.load();
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    formatCategories(data) {
      const categories = [];
      data.items.filter(({ parent }) => !parent).forEach((category) => {
        const firstItems = data.items
          .filter(({ parent }) => parent && parent.id === category.id);

        if (firstItems && firstItems.length > 0) {
          category.items = [];
          firstItems.forEach((first) => {
            const secondItems = data.items
              .filter(({ parent }) => parent && parent.id === first.id);

            if (secondItems && secondItems.length > 0) {
              first.items = [];
              secondItems.forEach((second) => {
                first.items.push(second);
              });
              category.items.push(first);
            } else {
              category.items.push(first);
            }
          });
        }
        categories.push(category);
      });
      return categories;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .categories-page {
    .category {
      margin: 1rem 0 2rem 0;
      .category-item {
        align-items: center;
        border-top: $border-width solid $border-color;
        display: flex;
        font-size: $font-size-lg;
        height: 2.4rem;
        justify-content: space-between;
        &:hover {
          background-color: $gray-color-ultra-light;
        }
      }
      .sub-item {
        border-top: $border-width solid $border-color;
        font-size: $font-size;
        &.secondary {
          padding-left: $layout-spacing-xl;
        }
        &.third {
          padding-left: $layout-spacing-xl * 2;
        }
      }
    }
    .empty {
      .action {
        margin: $layout-spacing-lg 0;
      }
    }
  }
</style>
