<template>
  <div>
    <div class="category-item" :class="classStyle ? classStyle : ''">
      <div>
        <span>{{ data.name }}</span><br>
        <small class="text-gray" v-if="data.parent && data.dre">
          {{ data.dre.name }}
        </small>
      </div>
      <div>
        <button
          class="btn btn-icon btn-action btn-neutral text-primary tooltip tooltip-left mr-1"
          :data-tooltip="`Nova sub categoria em [ ${data.name} ]`"
          v-if="newCategory"
          @click="open">
          <fa-icon :icon="['fal', 'plus']"/>
        </button>
        <button
          class="btn btn-icon btn-action btn-neutral tooltip tooltip-left mr-1"
          :data-tooltip="`Editar [ ${data.name} ]`"
          @click="edit">
          <fa-icon :icon="['fal', 'pencil']"/>
        </button>
        <button class="btn btn-icon btn-action btn-neutral text-error tooltip tooltip-left"
                :data-tooltip="`Excluir [ ${data.name} ]`"
                @click="remove">
          <fa-icon :icon="['fal', 'times']"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    classStyle: {
      type: String,
    },
    newCategory: {
      type: Boolean,
    },
  },
  methods: {
    open() {
      this.$emit('open', {
        id: this.data.id,
        type: this.data.type,
        dre: this.data.dre || null,
      });
    },
    edit() {
      this.$emit('edit', {
        id: this.data.id,
      });
    },
    close() {
      this.$emit('close');
    },
    remove() {
      this.$emit('remove', {
        id: this.data.id,
      });
    },
  },
};
</script>
