<template>
  <dx-modal :title="title()"
            :value="show"
            @input="close"
            id="modal-category">
    <div class="loading loading-lg" v-if="loading" />
    <div class="columns form-group" v-else>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.name.$error}">
        <label class="form-label">Nome</label>
        <input type="text" class="form-input"
               v-model="form.name"
               @blur="$v.form.name.$touch()"
               placeholder="Nome da categoria" v-focus>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Aparecer dentro de</label>
        <select class="form-select" v-model="form.parent.id">
          <option value="">[Categoria raiz]</option>
          <option v-for="(item, i) in parents"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.dre.id.$error}">
        <label class="form-label">DRE</label>
        <select class="form-select"
                v-model="form.dre.id"
                @blur="$v.form.dre.id.$touch()">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in dreItems"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-6 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.form.allowsValue.$error}">
        <label class="form-label">Permite lançar valores</label>
        <select class="form-select"
                v-model="form.allowsValue"
                @blur="$v.form.allowsValue.$touch()">
          <option value="">[Selecione]</option>
          <option value="false">Não</option>
          <option value="true">Sim</option>
        </select>
      </div>
      <div class="column col-6 col-md-12 col-sm-12 form-group">
        <label class="form-label">Código contábil</label>
        <input type="text" class="form-input"
               v-model="form.externalCode"
               placeholder="00.00.00.00">
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1"
              v-if="!loading"
              @click="save()" :disabled="saving"
              :class="{loading: saving}">
        Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    dre: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
    },
    parentId: {
      type: String,
    },
    categories: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      dreItems: [],
      parents: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.form.type = this.type;
    if (this.dre && this.dre.id) {
      this.form.dre = this.dre;
    }
    this.$v.form.$reset();
    this.loadDre();
    this.loadParents();
    this.form.parent.id = this.parentId;
    if (this.id) {
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        dre: {
          id: { required },
        },
        type: { required },
        allowsValue: { required },
        active: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/categories/${this.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http.get('/categories', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('categoria já adicionada', { type: 'error' });
        this.saving = false;
        return;
      }

      const category = this.clone(this.form);
      if ('dre' in category && category.dre.id) {
        category.dreId = category.dre.id;
      }

      if ('parent' in category && category.parent.id) {
        category.parentId = category.parent.id;
      }

      const request = !this.form.id
        ? this.$http.post('/categories', category)
        : this.$http.put(`/categories/${this.form.id}`, category);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/financial/categories/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    loadDre() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http.get('/dre', { params })
        .then(({ data }) => {
          this.dreItems = data.items;
        })
        .catch(() => {});
    },
    loadParents() {
      this.categories.forEach((category) => {
        this.parents.push({
          id: category.id,
          name: category.name,
        });
        if (category.items) {
          category.items.forEach((item) => {
            this.parents.push({
              id: item.id,
              name: item.name,
            });
          });
        }
      });
      this.parents.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    close() {
      this.$emit('close');
    },
    title() {
      if (!this.form.type) {
        return 'Carregando...';
      }
      const typeName = this.form.type === 'revenue' ? 'receita' : 'despesa';
      const stateName = this.id ? 'Editar' : 'Nova';
      return `${stateName} categoria de ${typeName}`;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: '',
        dre: {
          id: '',
          name: '',
        },
        parent: {
          id: '',
          name: '',
        },
        externalCode: '',
        allowsValue: '',
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
</style>
